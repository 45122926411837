<template>
    <div class="WorkTable">
        <div class="AncientTown">
            <div>
                <img @click="GoVRVisit" src="../../../assets/PersonalCenter/CopperOfficer.png" alt="">
            </div>
            <div>
                <img src="../../../assets/PersonalCenter/Jinggang.png" alt="">
            </div>
            <div>
                <img src="../../../assets/PersonalCenter/BookHall.png" alt="">
            </div>
            <div>
                <img src="../../../assets/PersonalCenter/JoeMouth.png" alt="">
            </div>
            <div>
                <img src="../../../assets/PersonalCenter/purchase.png" alt="">
            </div>
        </div>
        <div class="StateOperation">
            <div class="State">
                <div style="background-color: #7c8a7b;" :style="{ width: stateOperation == '创作中心' ? '100%' : '' }"
                    @click="stateOperation = '创作中心'">
                    创作中心
                </div>
                <div style="background-color: #a27e7e;" :style="{ width: stateOperation == '我的点赞' ? '100%' : '' }"
                    @click="stateOperation = '我的点赞'">
                    我的点赞
                </div>
                <div style="background-color: #887887;" :style="{ width: stateOperation == '我的收藏' ? '100%' : '' }"
                    @click="stateOperation = '我的收藏'">
                    我的收藏
                </div>
                <div style="background-color: #8a968e;" :style="{ width: stateOperation == '我要反馈' ? '100%' : '' }"
                    @click="stateOperation = '我要反馈'">
                    我要反馈
                </div>
            </div>
            <div class="ContentPresentation">
                <!-- 创作中心 -->
                <div class="Creation" v-if="stateOperation == '创作中心'">
                    <div class="Category">
                        <div v-for="(item, index) in category" :key="index" @click="labelStates(item)"
                            :class="label == item ? 'Label' : ''">
                            <p>{{ item }}</p>
                        </div>
                    </div>
                    <div class="WorksCategory">
                        <div v-for="(item, index) in transit" :key="index" @click="workLabel(item)">
                            <p :style="{ color: worksLabel == item ? '#7C8A7B' : '' }">{{ item }}</p>
                        </div>
                    </div>
                    <!-- 投稿 -->
                    <div class="Contribute" v-if="label == '投稿'">
                        <div class="File">
                            <!-- <img src="../../../assets/PersonalCenter/upload.png" alt="">
                        <p>拖拽到此上传</p> -->
                            <div>上传</div>
                        </div>
                        <div contenteditable="true" v-text="content" @input="handleInput" placeholder="添加描述符" class="Text">
                        </div>
                    </div>
                    <!-- 管理 -->
                    <div class="Works" v-if="label == '作品管理'">
                        <div class="WorksStates">
                            <div style="border-right: 1px solid #B8B8B8;">全部稿件0</div>
                            <div style="border-right: 1px solid #B8B8B8;">进行中0</div>
                            <div style="border-right: 1px solid #B8B8B8;">已通过0</div>
                            <div>未通过0</div>
                        </div>
                        <div class="WorksContent">
                            <div class="ContentItem">
                                <div class="Left">
                                    <img src="../../../assets/PersonalCenter/ChangPlay.png" alt="">
                                </div>
                                <div class="Right">
                                    <p>作品介绍</p>
                                    <img src="../../../assets/PersonalCenter/delete.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 我的点赞 -->
                <div class="MyThumbsBox" v-if="stateOperation == '我的点赞'">
                    <div class="MyThumbs">
                        <div class="MyThumbsTab">
                            <div>作品</div>
                            <div style="margin-left: 4vw;">商品</div>
                        </div>
                    </div>
                </div>
                <!-- 我的收藏 -->
                <div v-if="stateOperation == '我的收藏'"></div>
                <!-- 我要反馈 -->
                <div v-if="stateOperation == '我要反馈'"></div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    data() {
        return {
            stateOperation: '创作中心',
            label: '投稿',
            worksLabel: '视频创作投稿',
            category: ['投稿', '作品管理'],
            transit: ['视频创作投稿', '音频投稿', '素材投稿管理'],
            worksStates: ['视频创作管理', '音频投稿管理', '素材投稿管理'],
            contributeStates: ['视频创作投稿', '音频投稿', '素材投稿管理'],
            content: '',
        }
    },
    created() {
        this.MyThumbs();
    },
    methods: {
        // 我的点赞
        MyThumbs() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/customerLikes/page',
                headers: {
                    Authorization: 'Bearer ' + this.$cookies.get("token"),
                },
                data: {
                    pageSize: 5,
                    pageNum: 1
                }
            };
            axios(config)
                .then((res) => {
                })
                .catch(function (error) {
                });
        },
        // 跳转VR游览
        GoVRVisit() {
            this.$router.push("/vrVisit");
        },

        handleInput($event) {
            this.content = $event.target.innerText;
            // 拓展 如果想要只需要前100位数据
            this.content = this.content.substring(0, 100)
        },
        labelStates(States) {
            this.label = States
            if (States == '投稿') {
                this.transit = this.contributeStates
                this.worksLabel = '视频创作投稿'
            } else {
                this.transit = this.worksStates
                this.worksLabel = '视频创作管理'
            }
        },
        workLabel(States) {
            this.worksLabel = States
        }
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.WorkTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .AncientTown {
        border: 1px solid red;
        width: 96%;
        height: 20%;
        margin-top: 1%;
        display: flex;
        background-image: url("../../../assets/PersonalCenter/ancientTown.png");
        background-size: 100% 100%;
        justify-content: center;
        align-items: center;

        div {
            margin: 0 1vw;

            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
    }

    .StateOperation {
        width: 100%;
        height: 78%;
        display: flex;

        .State {
            height: 100%;
            width: 4%;
            display: flex;
            flex-direction: column;
            color: #FFFFFF;

            div {
                width: 60%;
                height: 18%;
                margin-top: 5%;
                border-radius: 0px 10px 10px 0px;
                writing-mode: tb-rl;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20% 0;
                font-size: 1.1vw;
                cursor: pointer;
            }
        }

        .ContentPresentation {
            display: flex;
            flex-direction: column;

            .Creation {
                display: flex;
                flex-direction: column;
                margin-left: 2vw;

                .Category {
                    display: flex;
                    color: #484848;

                    div {
                        margin: 1vh 1vw;
                        margin-right: 4vw;
                        font-size: 1.1vw;
                        cursor: pointer;
                    }

                    .Label {
                        color: #7C8A7B;
                        border-bottom: 2px solid #707070;
                    }
                }

                .WorksCategory {
                    display: flex;
                    color: #666666;

                    div {
                        margin: 1vh 1vw;
                        margin-right: 6vw;
                        font-size: 1.1vw;
                        cursor: pointer;
                    }
                }

                .Contribute {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 55vh;

                    .File {
                        width: 65vw;
                        height: 50%;
                        border: 1px dashed #A1A1A1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 1vh 1vw;

                        img {
                            width: 3vw;
                            height: 5vh;
                        }

                        p {
                            margin: 1vh 0;
                            color: #A1A1A1;
                            font-size: 0.9vw;
                        }

                        div {
                            width: 10vw;
                            height: 5vh;
                            margin-top: 1vh;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #FFFFFF;
                            font-size: 1vw;
                            background-color: #7C8A7B;
                            border-radius: 3px;
                        }
                    }

                    .Text {
                        width: 65vw;
                        height: 35%;
                        border: 1px dashed #A1A1A1;
                        margin: 1vh 1vw;
                        font-size: 1vw;
                    }

                    .Text[contenteditable]:empty:not(:focus):before {
                        content: attr(placeholder);
                        color: #aaa;
                    }
                }

                .Works {
                    border: 1px solid #707070;
                    width: 65vw;
                    height: 55vh;
                    background-color: #FFFFFF;
                    margin: 1vh 1vw;
                    display: flex;
                    flex-direction: column;

                    .WorksStates {
                        display: flex;
                        margin: 1vh 1vw;
                        color: #676767;

                        div {
                            padding: 0 1vw;
                            font-size: 1vw;
                            cursor: pointer;
                        }
                    }

                    .WorksContent {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 1vh 1vw;

                        .ContentItem {
                            width: 45%;
                            height: 20vh;
                            display: flex;
                            margin-right: 2vw;
                            border: 1px solid #CDCDCD;

                            .Left {
                                flex: 30%;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .Right {
                                flex: 70%;
                                height: 100%;
                                padding: 1vh 0.5vw;
                                display: flex;
                                justify-content: space-between;

                                img {
                                    width: 1.5vw;
                                    height: 3vh;
                                }
                            }
                        }
                    }
                }
            }
        }

        .MyThumbsBox {
            width: 75vw;
            height: 100%;
            border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: center;

            .MyThumbs {
                width: 90%;
                height: 95%;
                border: 1px solid red;

                .MyThumbsTab {
                    width: 100%;
                    height: 2.5vw;
                    display: flex;
                    font-size: 1.5vw;

                    div {
                        border-bottom: 3px solid #707070;
                    }
                }
            }
        }
    }
}</style>